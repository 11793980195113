import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import {Link} from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { checkStartUrl } from '../utils/functions'
import {graphql} from "gatsby"
const LoginExpired = () => {
  const {t} = useTranslation();
  if(checkStartUrl()){
    return <div></div>
  }
  return(
    <div className="">
      <HeardTopWrapper title={t('loginExpired')} />
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('loginExpired')}</title>
        </Helmet>
        <PageContainer>
          <div className="text-center">
            <div className="">
              <div>
                <p>
                {t('loginExpiredDes')} <Link to="/signin">{t('logIn')}</Link> {t('againToContinue')}
                </p>
              </div>
            </div>
          </div>
        </PageContainer>
      </Layout>
    </div>
  )
}

export default LoginExpired
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
